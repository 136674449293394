import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import Watermark from "components/Watermark";
import {
    styleBodyM,
    styleHeadingM,
    styleLabelM,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";
import { overlay } from "style/overlay";

const hoverColor = theme("theme", {
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary-alt",
    cyan: "--text-on-cyan-primary",
});

const headerBorderInitial = theme("theme", {
    lightgray: "--color-black-o00",
    blue: "--color-cyan-l60-o00",
    cyan: "--color-blue-o00",
});

const textColor = theme("theme", {
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

const linkColor = theme("theme", {
    lightgray: "--text-on-neutral-secondary",
    blue: "--text-on-blue-secondary",
    cyan: "--text-on-cyan-secondary",
});

const iconColor = theme("contentTheme", {
    black: "--icon-decorative-blue",
    cyan: "--icon-decorative-cyan",
    blue: "--icon-decorative-blue",
    white: "--text-on-image-primary",
});

export const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
`;

export const ImageWrapper = styled.div`
    aspect-ratio: 1.5;
    background: var(--block-blue-default);
    ${theme("useFallbackIcon", {
        true: css`
            &::after {
                z-index: 2;
                ${overlay("UpLeft")};
            }
        `,
    })}
    margin-bottom: 1.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    figure {
        inset: 0 0 -1px; /* fix rounding pixels issue */
        position: absolute;

        img {
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
            width: 100%;
            color: inherit;
        }
    }

    ${MQ.FROM_M} {
        margin-bottom: 2.1875rem;
    }

    ${MQ.FROM_XL} {
        margin-bottom: 2.625rem;
    }
`;

export const IconLarge = styled(Icon)`
    color: var(${iconColor});
    display: block;
    margin-bottom: 1.75rem;

    ${MQ.FROM_XL} {
        margin-bottom: 2.1875rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;
    width: 100%;

    ${MQ.FROM_M} {
        row-gap: 1.3125rem;
    }

    ${MQ.FROM_XL} {
        row-gap: 1.75rem;
    }
`;

export const Heading = styled.div`
    ${styleHeadingM};

    span {
        border-bottom: 1px solid var(${headerBorderInitial});
        transition: border-color 0.2s ease-in-out;
    }
`;

export const Introduction = styled.div`
    ${styleBodyM};
`;

export const LinkText = styled.div`
    ${styleLabelM};
    color: var(${linkColor});
    margin-right: 0.875rem;
    transition:
        margin-right 0.2s ease-in-out,
        color 0.2s ease-in-out;
`;

export const StyledWatermark = styled(Watermark)`
    pointer-events: none;
    z-index: 2;
`;

export const IconArrow = styled(Icon)`
    margin-right: 0.875rem;
    transition: margin-left 0.2s ease-in-out;
`;

export const Link = styled(NavLink)`
    ${columnGap};
    border-bottom: none;
    color: var(${textColor});
    display: flex;
    flex-direction: column;
    width: 100%;

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    &:hover {
        ${Heading} {
            span {
                border-color: currentcolor;
            }
        }

        ${ImageWrapper} {
            img {
                ${theme("isSafari", {
                    false: css`
                        transform: scale(1.04);
                    `,
                })};
            }
        }

        ${LinkText} {
            color: var(${hoverColor});
            margin-right: 0;
        }

        ${IconArrow} {
            margin-left: 0.875rem;
        }
    }
`;
