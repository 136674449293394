import React, { ReactElement, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";

import Image from "components/Image";
import { useBreakpoint } from "hooks";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";

import {
    Link,
    IconArrow,
    LinkText,
    Container,
    Content,
    Heading,
    ImageWrapper,
    IconLarge,
    Introduction,
    StyledWatermark,
} from "./PageGridItem.styled";
import PageGridItemProps from "./PageGridItemProps";

const PageGridItem = ({
    heading,
    className,
    icon,
    image,
    introduction,
    link,
    l18n,
}: PageGridItemProps): ReactElement => {
    const [isSafari, setIsSafari] = useState<boolean>(false);

    if (image?.caption) delete image.caption;
    if (image && image.url)
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            content: [
                {
                    width: 650,
                    height: Math.ceil(650 / 1.5),
                    breakpoint: BREAKPOINT_NUMBER.XL,
                },
                {
                    width: 500,
                    height: Math.ceil(500 / 1.5),
                    breakpoint: BREAKPOINT_NUMBER.M,
                },
                {
                    width: 768,
                    height: Math.ceil(768 / 1.5),
                },
            ],
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
        });

    useEffect(() => {
        typeof navigator !== "undefined" &&
            setIsSafari(
                navigator.userAgent.indexOf("Safari") !== -1 &&
                    navigator.userAgent.indexOf("Chrome") === -1,
            );
    }, []);

    let iconSize: string;
    switch (useBreakpoint(["XL"])) {
        case "XL":
            iconSize = "140";
            break;
        default:
            iconSize = "105";
    }

    let fallbackIcon: string;
    switch (useBreakpoint()) {
        case "XL":
        case "L":
        case "M":
            fallbackIcon = "link105";
            break;
        default:
            fallbackIcon = "link56";
    }

    const useFallbackIcon = !image?.url && !icon;

    return (
        <ThemeProvider
            theme={{ useFallbackIcon: useFallbackIcon, isSafari: isSafari }}
        >
            <Container className={className}>
                {link && (
                    <Link
                        to={link.url}
                        title={heading}
                        exact
                        prefetch={link.prefetch}
                        gtmClickType={link.gtmClickType || "PageGriditem"}
                    >

                            {useFallbackIcon ? (
                                <ImageWrapper>
                                    <StyledWatermark
                                        key={`Icon-${fallbackIcon || ""}`}
                                        icon={`${fallbackIcon}`}
                                    />
                                </ImageWrapper>
                            ) : icon ? (
                                <IconLarge
                                    key={`IconLarge-${icon}-${iconSize || ""}`}
                                    icon={`${icon}${iconSize}`}
                                    aria-hidden="true"
                                />
                            ) : (
                                <ImageWrapper>
                                    <Image {...image} />
                                </ImageWrapper>
                            )}

                        <Content>
                            {heading && (
                                <Heading>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: heading,
                                        }}
                                    />
                                </Heading>
                            )}

                            {introduction && (
                                <Introduction>{introduction}</Introduction>
                            )}
                            {link.linkText && (
                                <LinkText>
                                    <IconArrow
                                        icon={"longArrowRight28"}
                                        aria-hidden="true"
                                    />
                                    {link.linkText ||
                                        l18n?.readMore ||
                                        "Read more"}
                                </LinkText>
                            )}
                        </Content>
                    </Link>
                )}
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(PageGridItem);
